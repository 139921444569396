(function () {
  'use strict';

  /* @ngdoc object
   * @name people
   * @description
   *
   */
  angular
    .module('people', [
      'ui.router',
      'people.edit',
      'people.list',
      'people.view'
    ]);
}());
